import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html', 
  styleUrls: ['./footer.component.scss']
})



export class FooterComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router) { }

  environment = {
    VERSION: "1.0.0",
  };

  ngOnInit() {
    window.scroll(0,0);
  }

  goTo(url) {
    this.router.navigateByUrl(url);
  }

  goToWe() {
    this.router.navigateByUrl('/');
    window.location.hash='nosotros';
  }

  goToInit() {
    this.router.navigateByUrl('/');
    window.location.hash='banner';
  }

}
