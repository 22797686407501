import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BreadcrumbService } from 'xng-breadcrumb';
import { BusyService } from 'src/app/core/services/busy.service';
import { ToastrService } from 'ngx-toastr';
import { IResponse, IOrderData, IDataOrderItems } from '../shared/models/order';
import { OrderStatusService } from './order-status.service';

@Component({
  selector: 'app-order-status',
  templateUrl: './order-status.component.html',
  styleUrls: ['./order-status.component.scss']
})
export class OrderStatusComponent implements OnInit {
  idhash: string;
  emailOrder: string;
  empty: boolean;
  basketTotals: IOrderData;
  basketItems: IDataOrderItems[];
  orderNro: string;
  dateCreated: string;
  timeCreated: string;
  paymentMethod: string;
  email: string;
  promoCode: string;

  constructor(private orderStatusService: OrderStatusService,
              private busyService: BusyService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private toastr: ToastrService) { }

  ngOnInit() {
    window.scroll(0,0);
    this.empty = true;
    this.emailOrder = 'saravia@riseup.net';
    this.idhash = this.activatedRoute.snapshot.params.idhash;
    this.get();
    this.promoCode = "";
  }

  get() {
    this.orderStatusService.getFull(this.idhash).subscribe(response => {
      if ( response.status ) {

        this.orderNro =
          response.data.dateCreated.substring(0, 4) +
          '-' +
          response.data.orderID
        ;

        const event = new Date(response.data.dateCreated);
        const dateOptions = {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        };

        this.dateCreated =
          event.toLocaleDateString('es-ES', dateOptions)
        ;

        const timeOptions = {
          hour: '2-digit',
          minute: '2-digit'
        };

        this.timeCreated =
          event.toLocaleTimeString('en-US', timeOptions)
        ;

        this.empty = false;
        this.basketItems = response.data.items;
        this.basketTotals = response.data;
        this.paymentMethod = response.data.paymentMethod;
        this.promoCode = response.data.promoCode;
        this.email = response.data.email;
      } else {
        this.empty = true;
        this.basketItems = null;
        this.basketTotals = null;
      }
    }, error => {
      console.log(error);
    });
  }

}
