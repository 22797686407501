<!-- Banner -->
<div id="banner-page" class="container-fluid"
     style="background: url(/assets/images/graficos-banner.png) top, url(/assets/images/curso-detalle-banner.png);">
    <div class="row h-100 justify-content-center align-items-center">
        <div class="col-12 col-md-4 text-center">
            <h1 class="text-white display-3">
                {{ name }}
            </h1>
        </div>
    </div>
</div>
<!-- Fin Banner -->

<!-- Texto -->
<div *ngIf="!empty" class="bloque-texto">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-12 col-md-6 py-5 my-4 text-center">
                <div class="position-relative">
                    <div class="position-absolute video-dis text-white text-center w-100">
                        vista previa del curso
                    </div>
                    <figure>
                        <video id="player" playsinline controls poster='{{ poster }}'>
                            <source src="{{ sourceUrl }}" type="video/mp4"/>
                            <p><em>Su navegador no admite la etiqueta video, descargue el video en su lugar.</em></p>
                        </video>
                    </figure>
                </div>
            </div>
            <div class="col-12 col-md-6 py-5 my-4">
                <h2 class="text-center mb-2">
                    {{ name }}
                </h2>
                <div *ngIf="realPrice !== price &&
                            price !== ''   &&
                            price !== null &&
                            realPrice !== '' &&
                            realPrice !== null"
                     class="precio text-center mb-4">
                    <span class="antiguo mx-2 text-dark">
                        <del>
                            {{ realPrice | currency : 'S/ ' }}
                        </del>
                    </span>
                    <span class="actual actual-det mx-2 text-light-purple">
                        {{ price | currency : 'S/ ' }}
                    </span>
                </div>
                <div *ngIf="realPrice === price ||
                            price === ''   ||
                            price === null ||
                            realPrice === '' ||
                            realPrice === null"
                     class="precio text-center mb-4">
                    <span class="actual actual-det mx-2 text-light-purple">
                        {{ realPrice | currency : 'S/ ' }}
                    </span>
                </div>
                <div class="rating text-center mb-4">
                    <span *ngIf="valoration > 0" class="c-light">
                        Valoración:
                    </span>
                    <i *ngFor="let i of ratings(valoration)"
                       class="fas fa-star text-dark-purple">
                    </i>
                </div>
                <div class="rating text-center mb-4">
                    <span class="c-light">
                        Voto{{ votes === 1 ? '' : 's' }}:
                    </span>
                    {{ votes }}
                </div>
                <p class="text-center">
                    <span class="px-3">
                        <span class="c-light">
                            Categoría:
                        </span>
                        {{ category }}

                    </span>
                    <span class="px-3">
                        <span class="c-light">
                            Nivel:
                        </span>
                        {{ courseLevel }}
                    </span>
                    <span class="px-3">
                        <span class="c-light">
                            SKU:
                        </span>
                        {{ code }}
                    </span>
                </p>
                <p class="text-center">
                    {{ description }}
                </p>
                <div class="text-center p-4 display-4">
                  <a title="Compartir en Facebook"
                     onClick="return FBAPPS.SHARE(400, 300)"
                     href="https://facebook.com/sharer.php?u={{ url }}">
                    <i class="m-2 fab fa-facebook-square"
                       aria-hidden="true">
                    </i>
                  </a>
                  <a title="Compartir por whatsapp"
                     *ngIf="is_mobile()"
                     rel="external"
                     (click)="toWhatsApp('whatsapp://send?text='+url)"
                     [attr.data-action]="'share/whatsapp/share'">
                      <i class="m-2 fab fa-whatsapp text-light-purple"
                         aria-hidden="true">
                      </i>
                  </a>
                </div>
                <p class="text-center mb-4" *ngIf="enabled">
                    <a (click)="addToCart(productID,variantID)"
                        href="javascript: void(0)"
                        class="boton-corto">
                        inscribirse
                    </a>
                </p>
            </div>
        </div>
    </div>
</div>
<!-- Fin Texto -->

<!-- Descripción -->
<div *ngIf="!empty" class="container-fluid py-5">
    <div class="row align-items-start">
        <div class="col-12 col-md-9">
            <h3 class="mb-4">
                ¿A quién está dirigido este curso?
            </h3>
            <p *ngFor="let i of aimdes">
                {{ i.productExtensionName }}
            </p>
            <div class="row">
                <div class="col-12">
                    <h3 class="my-4">
                        ¿Qué aprenderás?
                    </h3>
                </div>
                <div class="col-12 col-md-4 d-flex mb-5" *ngFor="let o of youWillLearn">
                    <div class="pr-3 text-light-purple">
                        <i class="far fa-check-square">
                        </i>
                    </div>
                    <div>
                        {{ o.productExtensionName }}
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-3">
            <div class="autor p-5 text-white">
                <div class="text-center">
                    <img class="rounded-circle" width="129px" src="/assets/images/{{['miguel','ricardo','julio','dual'][selection]}}.jpeg"
                         alt="{{ instructor[selection].InstructorName }}">
                </div>
                <div class="text-center mt-4">
                    {{ instructor[selection].InstructorName }}
                </div>
                <div class="text-center text-light-grey mb-3">
                    {{ instructor[selection].Occupation }}
                </div>
                <p>
                    {{ instructor[selection].Description }}
                </p>
                <p class="text-center mt-4 mb-0">
                    <a href="javascript: void(0)" class="bg-white d-inline-block px-5 py-3">
                        {{ instructor[selection].InstructorTotalCourses }} curso{{ instructor[selection].InstructorTotalCourses === 1 ? ' ' : 's' }}
                    </a>
                </p>
            </div>
        </div>
    </div>
</div>
<!-- Fin Descripción -->

<!-- Requerimientos -->
<div *ngIf="!empty" class="container-fluid">
    <div class="row">
        <div class="col-12 col-md-3 py-5">
            <h3 class="mb-4"> Requerimientos de PC (Windows) </h3>
            <div class="d-flex mb-4" *ngFor="let requeriment of deviceRequirements">
                <div class="pr-3 text-light-purple"><i class="far fa-check-square"></i></div>
                <div>{{ requeriment.productExtensionName }}</div>
            </div>
        </div>
        <!-- contenido del curso -->
        <div class="col-12 col-md-9 py-5 contenidos-curso">
            <h3 class="mb-4"> Contenido del Curso </h3>
            <div class="pl-4" *ngFor="let contents of chapters">
                <p class="increase-line-height">
                    <strong>
                        {{ contents.productChapterName | uppercase }}
                    </strong>
                </p>
                <p class="increase-line-height">
                    {{ contents.productChapterDescription }}
                </p>
                <div class="d-flex mb-4" *ngFor="let content of contents.detalle">
                    <div class="pr-3 text-light-purple"><i class="far fa-check-square"></i></div>
                    <p><strong> {{ content.productChapterDetailName }} </strong> </p>
                    <div class="pr-3 text-light-purple"><strong>: </strong> </div>
                    <p>{{ content.productChapterDetailDescription }}</p>
                </div>
            </div>
        </div>
        <!-- FIN contenido del curso -->
    </div>
</div>
<!-- Fin Requerimientos -->

<!-- Cursos relacionados msg -->
<div *ngIf="!empty" class="container-fluid py-4 bg-light-purple">
    <div class="row">
        <div class="col text-white">
            <h2 class="mb-0">Los estudiantes también compraron</h2>
        </div>
    </div>
</div>
<!-- FIN Cursos relacionados msg -->

<!-- cursos relacionados -->
<div *ngIf="!empty" class="container-fluid px-0">
    <div class="row no-gutters">
        <div class="col-12 curso" *ngFor="let relatedProduct of RelatedCourses">
            <a href="javascript: void(0)"
               (click)="goToProduct('https://c-xperto.com' + relatedProduct.url);">
                <img class="w-100 lazy"
                     src="/assets/images/curso-empty.png"
                     data-src="{{ relatedProduct.imageFileName1 }}"
                     alt="{{ relatedProduct.name }}">
                <div class="curso-info p-4 text-center">
                    <h3> {{ relatedProduct.name }} </h3>
                    <!-- \\ realprice template -->
                    <div *ngIf="relatedProduct.realPrice !== relatedProduct.price &&
                                relatedProduct.price !== ''   &&
                                relatedProduct.price !== null &&
                                relatedProduct.realPrice !== '' &&
                                relatedProduct.realPrice !== null"
                         class="precio">
                        <span class="actual mx-2">
                            S/ {{ relatedProduct.price | number:'1.2' }}
                        </span>
                        <span class="antiguo mx-2">
                            <del>
                                S/ {{ relatedProduct.realPrice | number:'1.2' }}
                            </del>
                        </span>
                    </div>
                    <div *ngIf="relatedProduct.realPrice === relatedProduct.price ||
                                relatedProduct.price === ''   ||
                                relatedProduct.price === null ||
                                relatedProduct.realPrice === '' ||
                                relatedProduct.realPrice === null"
                         class="precio">
                        <span class="actual mx-2">S/
                            {{ relatedProduct.price === '' ? relatedProduct.price : relatedProduct.realPrice }}
                        </span>
                    </div>
                    <!-- // realprice template -->
                </div>
            </a>
            <div class="curso-detalles p-4">
                <div class="curso-detalles-contenidos bg-white p-4">
                    <p class="h1 text-center lh-30"> {{ relatedProduct.name}} </p>
                    <!-- \\ realprice template -->
                    <div *ngIf="relatedProduct.realPrice !== relatedProduct.price &&
                                relatedProduct.price !== ''   &&
                                relatedProduct.price !== null &&
                                relatedProduct.realPrice !== '' &&
                                relatedProduct.realPrice !== null"
                         class="precio-detalle text-center mb-4 h1">
                        <span class="actual mx-2">
                            S/ {{ relatedProduct.price | number:'1.2' }}
                        </span>
                        <span class="antiguo mx-2">
                            <del>
                                S/ {{ relatedProduct.realPrice | number:'1.2' }}
                            </del>
                        </span>
                    </div>
                    <div *ngIf="relatedProduct.realPrice === relatedProduct.price ||
                                relatedProduct.price === ''   ||
                                relatedProduct.price === null ||
                                relatedProduct.realPrice === '' ||
                                relatedProduct.realPrice === null"
                         class="precio-detalle text-center mb-4 h1">
                        <span class="actual mx-2">S/
                            {{ relatedProduct.price === '' ? relatedProduct.price : relatedProduct.realPrice  }}
                        </span>
                    </div>
                    <!-- // realprice template -->
                    <div class="row curso-checks mb-4 justify-content-center">
                        <div class="col-9 px-0">
                            <p>
                                {{ relatedProduct.description.substring(0, 180) }} ...
                            </p>
                        </div>
                    </div>

                    <div class="row justify-content-center align-items-center botones">
                        <div *ngIf="relatedProduct.enabled" class="col-9">
                            <a href="javascript: void(0)"
                               (click)="addToCart(relatedProduct.productID,relatedProduct.variantID)"
                               class="h1 inscribirse-boton">
                                inscribirse
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
<!-- Fin Cursos Relacionados -->

<!-- Valoración -->
<div *ngIf="!empty" class="container-fluid py-5">
    <div class="row">

        <div class="col-12 mb-5">
            <h3>
                Comentarios de los estudiantes
            </h3>
        </div>
        <!-- \\ Right valorations -->
        <div class="col-12 col-md-3">
            <div class="text-center valoracion font-weight-bold">
                {{ valoration }}
            </div>
            <div class="rating display-4 text-center text-light-purple mb-3">
                <i *ngFor="let i of ratings(valoration)"
                   class="fas fa-star">
                </i>
            </div>
            <p class="text-center">
                Valoración abierta
            </p>
            <div *ngFor="let valoration of MoreValorations"
                 class="d-flex justify-content-center">
                <div>
                    <div class="rating mb-2">
                        <i *ngFor="let i of ratings(valoration.rating)"
                           class="fas text-light-purple fa-star">
                        </i>
                        ({{ valoration.totals }})
                    </div>
                </div>
            </div>
        </div>
        <!-- // Right comment -->
        <div id="inputComment" class="col-12 col-md-9">
            <div class="row testimonio-det mb-5"
                 *ngFor="let comment of commentProducstList">
                <div class="col-12 col-md-12 text-center text-md-left">
                    <p>
                        {{ comment.comment }}
                    </p>
                    <div class="rating text-right mt-4">
                        <span class="text-left mr-3 ml-3">{{ formattedDate(comment.dateCreated) }}</span>
                        <i *ngFor="let i of ratings(comment.rating)"
                           class="fas text-light-purple fa-star">
                        </i>
                    </div>
                </div>
            </div>
            <div *ngIf="!hideSendComment" class="row testimonio-det mb-5">
                <div class="col-12 col-md-12 text-center text-md-left">

                    <textarea id="comment"
                              (keypress)="hasTypeComment($event)"
                              class="form-control comment-textarea"
                              rows="5" cols="70" required></textarea>

                    <div class="rating text-right mt-4">
                        <a href="javascript: void(0)"
                           (click)="sendCalification(1)"
                           (mouseover)="starMourseOver(1)"
                           class="{{ start1 ? 'fas' : 'far'}} fa-star start-light-purple-calification">
                        </a>
                        <a href="javascript: void(0)"
                           (click)="sendCalification(2)"
                           (mouseover)="starMourseOver(2)"
                           class="{{ start2 ? 'fas' : 'far'}} fa-star start-light-purple-calification">
                        </a>
                        <a href="javascript: void(0)"
                           (click)="sendCalification(3)"
                           (mouseover)="starMourseOver(3)"
                           class="{{ start3 ? 'fas' : 'far'}} fa-star start-light-purple-calification">
                        </a>
                        <a href="javascript: void(0)"
                           (click)="sendCalification(4)"
                           (mouseover)="starMourseOver(4)"
                           class="{{ start4 ? 'fas' : 'far'}} fa-star start-light-purple-calification">
                        </a>
                        <a href="javascript: void(0)"
                           (click)="sendCalification(5)"
                           (mouseover)="starMourseOver(5)"
                           class="{{ start5 ? 'fas' : 'far'}} fa-star start-light-purple-calification">
                        </a>
                        <button type="button"
                                class="btn btn-primary-gold"
                                (click)="sendComment()">{{ commentMsgButton }}</button>
                    </div>
                </div>
            </div>

        </div>

    </div>
</div>
<!-- Fin Valoración -->
