<!-- Cursos -->
<div class="container-fluid px-0">
    <div class="row no-gutters">
        <div *ngFor="let product of products" class="col-12 curso">
            <a href="javascript: void(0)" (click)="goToProduct(product.url)">
                <img alt="{{product.name}}"
                     class="w-100 lazy img-border-top"
                     src="assets/images/curso-empty.png"
                     data-src="{{ product.imageFileName1 }}">
                <div class="curso-info p-4 text-center">
                    <h3>{{ product.name }}</h3>

                    <!-- \\ realprice template -->
                    <div *ngIf="product.realPrice !== product.price &&
                                product.price !== ''   &&
                                product.price !== null &&
                                product.realPrice !== '' &&
                                product.realPrice !== null"
                         class="precio">
                        <span class="actual mx-2">
                            {{ product.price | currency : 'S/ ' }}
                        </span>
                        <span class="antiguo mx-2">
                            <del>
                                {{ product.realPrice | currency : 'S/ ' }}
                            </del>
                        </span>
                    </div>
                    <div *ngIf="product.realPrice === product.price ||
                                product.price === ''   ||
                                product.price === null ||
                                product.realPrice === '' ||
                                product.realPrice === null"
                         class="precio">
                        <span class="actual mx-2">
                            {{ product.price === '' ? product.price : product.realPrice | currency : 'S/ ' }}
                        </span>
                    </div>
                    <!-- // realprice template -->

                </div>
            </a>
            <div class="curso-detalles p-4">
                <div class="curso-detalles-contenidos bg-white p-4">
                    <p class="h1 text-center lh-30"> {{ product.name }} </p>
                    <div *ngIf="product.realPrice !== product.price &&
                                product.price !== ''   &&
                                product.price !== null &&
                                product.realPrice !== '' &&
                                product.realPrice !== null"
                         class="precio-detalle text-center mb-4 h1">
                        <span class="actual mx-2">
                            {{ product.price | currency : 'S/ ' }}
                        </span>
                        <span class="antiguo mx-2">
                            <del>
                                {{ product.realPrice | currency : 'S/ ' }}
                            </del>
                        </span>
                    </div>
                    <div *ngIf="product.realPrice === product.price ||
                                product.price === ''   ||
                                product.price === null ||
                                product.realPrice === '' ||
                                product.realPrice === null"
                         class="precio-detalle text-center mb-4 h1">
                        <span class="actual mx-2">
                            {{ product.price === '' ? product.price : product.realPrice | currency : 'S/ ' }}
                        </span>
                    </div>
                    <!-- // realprice template -->

                    <div class="row curso-checks mb-4 justify-content-center">
                        <div class="col-9 px-0">
                            <p> {{ product.description.substring(0, 180) }} ...</p>
                            <p class="boldlink text-center"
                               (click)="goToProduct(product.url)">
                                seguir leyendo...
                            </p>
                        </div>
                    </div>
                    <div class="row justify-content-center align-items-center botones">
                        <div class="col-9">
                            <a *ngIf="product.enabled" href="javascript: void(0)"
                               (click)="addToCart(product.productID,product.variantID,product)"
                               class="h1 inscribirse-boton">
                                inscribirse
                            </a>
                        </div>
                        <!-- <div class="col-2 text-center"> -->
                        <!--     <i class="{{ desire.indexOf(product.productID) !== -1 ? 'fas' : 'far' }} fa-heart" -->
                        <!--        (click)="clickerSwitchDesireArray(product.productID)"> -->
                        <!--     </i> -->
                        <!-- </div> -->

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Fin Curso -->
