<!-- Banner -->
<div id="banner-page"
     class="banner-top container-fluid"
     [ngStyle]="{ 'background' : imageFileName }">
    <div class="row h-100 justify-content-center align-items-center">
        <div class="col-12 col-md-4 text-center">
            <h1 class="text-white display-3">{{ category }}</h1>
        </div>
    </div>
</div>
<!-- Fin Banner -->

<!-- Texto -->
<div *ngIf="false" class="container-fluid">
    <div class="row">
        <div class="col py-5">
            <p> {{ shortDescription }} </p>
        </div>
    </div>
</div>
<!-- FIn Texto -->

<!-- Filtro -->
<div *ngIf="false" class="container-fluid filtro py-3">
    <div class="row justify-content-end">
        <div class="col-12 col-md-3 my-3">
            <input type="search" placeholder="Buscar">
        </div>
        <div class="col-12 col-md-3 my-3">
            <select  id="">
                <option>Ordenar por</option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
            </select>
        </div>
    </div>
</div>
<!-- Fin FIltro -->

<!-- Cursos -->
<div *ngIf="!empty" class="container-fluid px-0">
    <div class="row no-gutters">

        <div class="col-12 curso" *ngFor="let product of products">
            <a href="javascript: void(0)" (click)="goToProduct(product.url)">
                <img class="w-100 lazy"
                     src="/assets/images/curso-empty.png"
                     data-src="{{ product.imageFileName1 }}"
                     alt="{{ product.name }}">
                <div class="curso-info p-4 text-center">
                    <h3>{{ product.name }}</h3>
                    <!--
                    <div *ngIf="product.rating"
                         class="rating">
                        <i *ngFor="let i of ratings(product.Valoration)"
                           class="fas fa-star">
                        </i>
                        {{ product.Votes }} voto{{ product.Votes === 1 ? ' ' : 's' }}
                    </div>
                    <div *ngIf="product.teacherName"
                         class="profesor">
                        {{ product.teacherName }}
                    </div>
                    -->
                    <!-- \\ realprice template -->
                    <div *ngIf="product.realPrice !== product.price &&
                                product.price !== ''   &&
                                product.price !== null &&
                                product.realPrice !== '' &&
                                product.realPrice !== null"
                         class="precio">
                        <span class="actual mx-2">
                            {{ product.price | currency : 'S/ ' }}
                        </span>
                        <span class="antiguo mx-2">
                            <del>
                                {{ product.realPrice | currency : 'S/ ' }}
                            </del>
                        </span>
                    </div>
                    <div *ngIf="product.realPrice === product.price ||
                                product.price === ''   ||
                                product.price === null ||
                                product.realPrice === '' ||
                                product.realPrice === null"
                         class="precio">
                        <span class="actual mx-2">
                            {{ product.price === '' ? product.price : product.realPrice | currency : 'S/ ' }}
                        </span>
                    </div>
                    <!-- // realprice template -->

                </div>
            </a>
            <div class="curso-detalles p-4">
                <div class="curso-detalles-contenidos bg-white p-4">
                    <p class="h1 text-center lh-30"> {{ product.name }} </p>
                    <!--
                    <p class="text-light-purple text-center">
                        {{ product.TimeHourCount === null ||
                        product.TimeHourCount === 0 ||
                        product.TimeHourCount === '' ? 'No especifíca ' :
                        product.TimeHourCount + ' ' }}
                        hora{{ product.TimeHourCount === 1 ? ' ' : 's' }}
                        <span class="px-3">|</span>
                        Nivel: {{ product.Level === '' || product.Level === null ? 'No especificado' : product.Level }}
                    </p>
                    -->
                    <!-- \\ realprice template -->
                    <div *ngIf="product.realPrice !== product.price &&
                                product.price !== ''   &&
                                product.price !== null &&
                                product.realPrice !== '' &&
                                product.realPrice !== null"
                         class="precio-detalle text-center mb-4 h1">
                        <span class="actual mx-2">
                            {{ product.price | currency : 'S/ ' }}
                        </span>
                        <span class="antiguo mx-2">
                            <del>
                                {{ product.realPrice | currency : 'S/ ' }}
                            </del>
                        </span>
                    </div>
                    <div *ngIf="product.realPrice === product.price ||
                                product.price === ''   ||
                                product.price === null ||
                                product.realPrice === '' ||
                                product.realPrice === null"
                         class="precio-detalle text-center mb-4 h1">
                        <span class="actual mx-2">
                            {{ product.price === '' ? product.price : product.realPrice | currency : 'S/ ' }}
                        </span>
                    </div>
                    <!-- // realprice template -->

                    <div class="row curso-checks mb-4 justify-content-center">
                        <div class="col-9 px-0">
                            <p> {{ product.description.substring(0, 180) }} ...</p>
                            <p class="boldlink text-center"
                               (click)="goToProduct(product.url)">
                                seguir leyendo...
                            </p>
                        </div>
                    </div>
                    <div class="row justify-content-center align-items-center botones">
                        <div class="col-9">
                            <a *ngIf="product.enabled" href="javascript: void(0)"
                               (click)="addToCart(product.productID,product.variantID,product)"
                               class="h1 inscribirse-boton">
                                inscribirse
                            </a>
                        </div>
                        <!-- <div class="col-2 text-center"> -->
                            <!--     <i class="{{ desire.indexOf(product.productID) !== -1 ? 'fas' : 'far' }} fa-heart" -->
                                        <!--        (click)="clickerSwitchDesireArray(product.productID)"> -->
                                        <!--     </i> -->
                                        <!-- </div> -->
                                        </div>
                </div>
            </div>
        </div>

    </div>
</div>
<!-- Fin Curso -->
