import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { CategoriesComponent } from './categories/categories.component';
import { TestErrorComponent } from './core/test-error/test-error.component';
import { ServerErrorComponent } from './core/server-error/server-error.component';
import { NotFoundComponent } from './core/not-found/not-found.component';
import { AuthGuard } from './core/guards/auth.guard';
import { CategoryComponent } from './category/category.component';
import { ArticleComponent } from './article/article.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
import { OrderStatusComponent } from './order-status/order-status.component';
import { CourseComponent } from './course/course.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { MetafrenzyModule, MetafrenzyGuard } from 'ngx-metafrenzy';

const routes: Routes = [
  { path: '', component: HomeComponent,
    data: {
      metafrenzy: {
        title: 'My Title',
        tags: [
          {
            name: 'og:title',
            content: 'My title'
          }, {
            name: 'og:description',
            content: 'My description'
          }
        ],
        links: [
          {
            rel: 'canonical',
            href: 'http://localhost/'
          }
        ]
      },
      breadcrumb: 'Home'
    },
    canActivate: [MetafrenzyGuard]
  },
  { path:  'categorias', component: CategoriesComponent, data: { breadcrumb: 'Categories' } },
  { path:  'categoria/:CategoryID/:category', component: CategoryComponent, data:
    {breadcrumb: {alias: 'categoryDetail'}} },
  { path:  'articulo/:ProductID/:VariantID/:Name', component: ArticleComponent, data:
    {breadcrumb: {alias: 'articleDetail'}} },
  { path:  'curso/:productID/:variantID/:orderItemID/:description', component: CourseComponent, data:
    {breadcrumb: {alias: 'courseDetail'}} },
  { path:  'gracias/:idhash', component: ThankyouComponent, data:
    {breadcrumb: {alias: 'thankyouDetail'}} },
  { path:  'estado-orden/:idhash', component: OrderStatusComponent, data:
    {breadcrumb: {alias: 'orderStatusDetail'}} },
  { path: 'test-error', component: TestErrorComponent, data: { breadcrumb: 'Test Errors' } },
  { path: 'server-error', component: ServerErrorComponent, data: { breadcrumb: 'Server Error' } },
  { path: '404', component: NotFoundComponent, data: { breadcrumb: 'Not Found' } },
  { path: 'shop', loadChildren: () => import('./shop/shop.module').then(mod => mod.ShopModule), data: { breadcrumb: 'Shop' } },
  { path: 'carrito', loadChildren: () => import('./basket/basket.module').then(mod => mod.BasketModule), data: { breadcrumb: 'Basket' } },
  { path: 'forma-pago', component: CheckoutComponent, data: { breadcrumb: 'Checkout' } },
  {
    path: 'orders',
    canActivate: [AuthGuard],
    loadChildren: () => import('./orders/orders.module')
      .then(mod => mod.OrdersModule), data: { breadcrumb: 'Orders' }
  },
  {
    path: 'cuenta',
    loadChildren: () => import('./account/account.module')
      .then(mod => mod.AccountModule), data: { breadcrumb: { skip: true } }
  },
  {
    path: 'creator',
    loadChildren: () => import('./creator/creator.module')
      .then(mod => mod.CreatorModule), data: { breadcrumb: { skip: true } }
  },
  {
    path: 'creator-admin',
    loadChildren: () => import('./creator-admin/creator-admin.module')
      .then(mod => mod.CreatorAdminModule), data: { breadcrumb: { skip: true } }
  },
  { path: '**', redirectTo: '404', pathMatch: 'full' },
  { path:  'olvide-mi-clave/:hash', component: ForgotPasswordComponent, data: { breadcrumb: 'ForgotPassword' } }
];

@NgModule({
  imports: [
    MetafrenzyModule.forRoot(),
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
