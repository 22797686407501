import { Component, OnInit } from '@angular/core';
import { AccountService } from '../account/account.service';
import { BasketService } from '../basket/basket.service';
import { CheckoutService } from '../checkout/checkout.service';
import { IBasketTotals } from '../shared/models/basket';
import { Router, ActivatedRoute } from '@angular/router';
import { IRequestCart, IBasketItem, IBasketResponse, IBasket } from '../shared/models/basket';
import { FormGroup, FormBuilder, Validators, AsyncValidatorFn, ReactiveFormsModule } from '@angular/forms';
import { timer, of, Observable } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { ICountry } from 'src/app/shared/models/country';
import { IInstructionGrade } from 'src/app/shared/models/instructionGrade';
import { IUser } from 'src/app/shared/models/user';
import { BusyService } from 'src/app/core/services/busy.service';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

declare const document: any;

declare const ga: any;

declare const fbq: any;

declare const GAPPS: any;

declare const COUNTCART: any;

declare var md5: any;

function count_cart_items_quantity(op,count) {
  COUNTCART.QUANTITY(op,count);
}

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})

export class CheckoutComponent implements OnInit {

  countryCode: string;
  cardForm: FormGroup;
  dropdownCountryInvalid: boolean;
  dropdownInstructionGradeInvalid: boolean;
  errors: boolean;
  message: string;
  messages: string[];
  registerButtonStatus: boolean;
  IAcceptTermsAndConditions: boolean;
  returnUrl: string;
  currentUser: IUser;
  isExistsUser: boolean;
  currentUserToken: string;
  basket: IBasketResponse;
  requestCart: IRequestCart;
  basketItems: IBasketItem[];
  basketTotals: IBasket;
  empty: boolean;
  totals: string;
  promoCode: string;
  cardNumber: string;
  name: string;
  CCV: string;
  login: string;
  documentNumber: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  postalCode: string;
  phoneNumber: string;
  payusecuritycode: string;  
  payusecurity: string;  
  payusecuritySafe: SafeResourceUrl;  


  listCardTypes = [
    { cardTypeID: 1, cardTypeName: 'Débito'  },
    { cardTypeID: 2, cardTypeName: 'Credito' }
  ];

  listYear = [
    { yearID: 0, year: 'Año' },
    { yearID: 1, year: '2020' },
    { yearID: 2, year: '2021' },
    { yearID: 3, year: '2022' },
    { yearID: 4, year: '2023' },
    { yearID: 5, year: '2024' },
    { yearID: 6, year: '2025' },
    { yearID: 7, year: '2026' },
    { yearID: 8, year: '2027' },
    { yearID: 9, year: '2028' }
  ];

  listMonthNumber = [
    { monthNumberID: 0, monthNumberName: 'Mes' },
    { monthNumberID: 1, monthNumberName: '1' },
    { monthNumberID: 2, monthNumberName: '2' },
    { monthNumberID: 3, monthNumberName: '3' },
    { monthNumberID: 4, monthNumberName: '4' },
    { monthNumberID: 5, monthNumberName: '5' },
    { monthNumberID: 6, monthNumberName: '6' },
    { monthNumberID: 7, monthNumberName: '7' },
    { monthNumberID: 8, monthNumberName: '8' },
    { monthNumberID: 9, monthNumberName: '9' },
    { monthNumberID: 10, monthNumberName: '10' },
    { monthNumberID: 11, monthNumberName: '11' },
    { monthNumberID: 12, monthNumberName: '12' }
  ];

  listAllCountries = [
    {'countryID':1, 'countryCode':'US', 'countryName':'United States'},
    {'countryID':2, 'countryCode':'AF', 'countryName':'Afghanistan'},
    {'countryID':3, 'countryCode':'AL', 'countryName':'Albania'},
    {'countryID':4, 'countryCode':'DZ', 'countryName':'Algeria'},
    {'countryID':5, 'countryCode':'AS', 'countryName':'American Samoa'},
    {'countryID':6, 'countryCode':'AD', 'countryName':'Andorra'},
    {'countryID':7, 'countryCode':'AO', 'countryName':'Angola'},
    {'countryID':8, 'countryCode':'AI', 'countryName':'Anguilla'},
    {'countryID':9, 'countryCode':'AQ', 'countryName':'Antarctica'},
    {'countryID':10, 'countryCode':'AG', 'countryName':'Antigua and Barbuda'},
    {'countryID':11, 'countryCode':'AR', 'countryName':'Argentina'},
    {'countryID':12, 'countryCode':'AM', 'countryName':'Armenia'},
    {'countryID':13, 'countryCode':'AW', 'countryName':'Aruba'},
    {'countryID':14, 'countryCode':'AU', 'countryName':'Australia'},
    {'countryID':15, 'countryCode':'AT', 'countryName':'Austria'},
    {'countryID':16, 'countryCode':'AZ', 'countryName':'Azerbaijan'},
    {'countryID':17, 'countryCode':'BS', 'countryName':'Bahamas'},
    {'countryID':18, 'countryCode':'BH', 'countryName':'Bahrain'},
    {'countryID':19, 'countryCode':'BD', 'countryName':'Bangladesh'},
    {'countryID':20, 'countryCode':'BB', 'countryName':'Barbados'},
    {'countryID':21, 'countryCode':'BY', 'countryName':'Belarus'},
    {'countryID':22, 'countryCode':'BE', 'countryName':'Belgium'},
    {'countryID':23, 'countryCode':'BZ', 'countryName':'Belize'},
    {'countryID':24, 'countryCode':'BJ', 'countryName':'Benin'},
    {'countryID':25, 'countryCode':'BM', 'countryName':'Bermuda'},
    {'countryID':26, 'countryCode':'BT', 'countryName':'Bhutan'},
    {'countryID':27, 'countryCode':'BO', 'countryName':'Bolivia'},
    {'countryID':28, 'countryCode':'BA', 'countryName':'Bosnia and Herzegovina'},
    {'countryID':29, 'countryCode':'BW', 'countryName':'Botswana'},
    {'countryID':30, 'countryCode':'BV', 'countryName':'Bouvet Island'},
    {'countryID':31, 'countryCode':'BR', 'countryName':'Brazil'},
    {'countryID':32, 'countryCode':'IO', 'countryName':'British Indian Ocean Territory'},
    {'countryID':33, 'countryCode':'BN', 'countryName':'Brunei Darussalam'},
    {'countryID':34, 'countryCode':'BG', 'countryName':'Bulgaria'},
    {'countryID':35, 'countryCode':'BF', 'countryName':'Burkina Faso'},
    {'countryID':36, 'countryCode':'BI', 'countryName':'Burundi'},
    {'countryID':37, 'countryCode':'KH', 'countryName':'Cambodia'},
    {'countryID':38, 'countryCode':'CM', 'countryName':'Cameroon'},
    {'countryID':39, 'countryCode':'CA', 'countryName':'Canada'},
    {'countryID':40, 'countryCode':'CV', 'countryName':'Cape Verde'},
    {'countryID':41, 'countryCode':'KY', 'countryName':'Cayman Islands'},
    {'countryID':42, 'countryCode':'CF', 'countryName':'Central African Republic'},
    {'countryID':43, 'countryCode':'TD', 'countryName':'Chad'},
    {'countryID':44, 'countryCode':'CL', 'countryName':'Chile'},
    {'countryID':45, 'countryCode':'CN', 'countryName':'China'},
    {'countryID':46, 'countryCode':'CX', 'countryName':'Christmas Island'},
    {'countryID':47, 'countryCode':'CC', 'countryName':'Cocos (Keeling) Islands'},
    {'countryID':48, 'countryCode':'CO', 'countryName':'Colombia'},
    {'countryID':49, 'countryCode':'KM', 'countryName':'Comoros'},
    {'countryID':50, 'countryCode':'CG', 'countryName':'Congo'},
    {'countryID':51, 'countryCode':'CD', 'countryName':'Congo, Democratic Republic of the'},
    {'countryID':52, 'countryCode':'CK', 'countryName':'Cook Islands'},
    {'countryID':53, 'countryCode':'CR', 'countryName':'Costa Rica'},
    {'countryID':54, 'countryCode':'CI', 'countryName':'Cote d\'Ivoire'},
    {'countryID':55, 'countryCode':'HR', 'countryName':'Croatia'},
    {'countryID':56, 'countryCode':'CU', 'countryName':'Cuba'},
    {'countryID':57, 'countryCode':'CY', 'countryName':'Cyprus'},
    {'countryID':58, 'countryCode':'CZ', 'countryName':'Czech Republic'},
    {'countryID':59, 'countryCode':'DK', 'countryName':'Denmark'},
    {'countryID':60, 'countryCode':'DJ', 'countryName':'Djibouti'},
    {'countryID':61, 'countryCode':'DM', 'countryName':'Dominica'},
    {'countryID':62, 'countryCode':'DO', 'countryName':'Dominican Republic'},
    {'countryID':63, 'countryCode':'TP', 'countryName':'East Timor'},
    {'countryID':64, 'countryCode':'EC', 'countryName':'Ecuador'},
    {'countryID':65, 'countryCode':'EG', 'countryName':'Egypt'},
    {'countryID':66, 'countryCode':'SV', 'countryName':'El Salvador'},
    {'countryID':67, 'countryCode':'GQ', 'countryName':'Equatorial Guinea'},
    {'countryID':68, 'countryCode':'ER', 'countryName':'Eritrea'},
    {'countryID':69, 'countryCode':'EE', 'countryName':'Estonia'},
    {'countryID':70, 'countryCode':'ET', 'countryName':'Ethiopia'},
    {'countryID':71, 'countryCode':'FK', 'countryName':'Falkland Islands (Malvinas)'},
    {'countryID':72, 'countryCode':'FO', 'countryName':'Faroe Islands'},
    {'countryID':73, 'countryCode':'FJ', 'countryName':'Fiji'},
    {'countryID':74, 'countryCode':'FI', 'countryName':'Finland'},
    {'countryID':75, 'countryCode':'FR', 'countryName':'France'},
    {'countryID':76, 'countryCode':'FX', 'countryName':'France, Metropolitan'},
    {'countryID':77, 'countryCode':'GF', 'countryName':'French Guiana'},
    {'countryID':78, 'countryCode':'PF', 'countryName':'French Polynesia'},
    {'countryID':79, 'countryCode':'TF', 'countryName':'French Southern Territories'},
    {'countryID':80, 'countryCode':'GA', 'countryName':'Gabon'},
    {'countryID':81, 'countryCode':'GM', 'countryName':'Gambia'},
    {'countryID':82, 'countryCode':'GE', 'countryName':'Georgia'},
    {'countryID':83, 'countryCode':'DE', 'countryName':'Germany'},
    {'countryID':84, 'countryCode':'GH', 'countryName':'Ghana'},
    {'countryID':85, 'countryCode':'GI', 'countryName':'Gibraltar'},
    {'countryID':86, 'countryCode':'GR', 'countryName':'Greece'},
    {'countryID':87, 'countryCode':'GL', 'countryName':'Greenland'},
    {'countryID':88, 'countryCode':'GD', 'countryName':'Grenada'},
    {'countryID':89, 'countryCode':'GP', 'countryName':'Guadaloupe'},
    {'countryID':90, 'countryCode':'GU', 'countryName':'Guam'},
    {'countryID':91, 'countryCode':'GT', 'countryName':'Guatemala'},
    {'countryID':92, 'countryCode':'GN', 'countryName':'Guinea'},
    {'countryID':93, 'countryCode':'GW', 'countryName':'Guinea-Bissau'},
    {'countryID':94, 'countryCode':'GY', 'countryName':'Guyana'},
    {'countryID':95, 'countryCode':'HT', 'countryName':'Haiti'},
    {'countryID':96, 'countryCode':'HM', 'countryName':'Heard Island and McDonald Islands'},
    {'countryID':97, 'countryCode':'HN', 'countryName':'Honduras'},
    {'countryID':98, 'countryCode':'HK', 'countryName':'Hong Kong'},
    {'countryID':99, 'countryCode':'HU', 'countryName':'Hungary'},
    {'countryID':100, 'countryCode':'IS', 'countryName':'Iceland'},
    {'countryID':101, 'countryCode':'IN', 'countryName':'India'},
    {'countryID':102, 'countryCode':'ID', 'countryName':'Indonesia'},
    {'countryID':103, 'countryCode':'IR', 'countryName':'Iran'},
    {'countryID':104, 'countryCode':'IQ', 'countryName':'Iraq'},
    {'countryID':105, 'countryCode':'IE', 'countryName':'Ireland'},
    {'countryID':106, 'countryCode':'IL', 'countryName':'Israel'},
    {'countryID':107, 'countryCode':'IT', 'countryName':'Italy'},
    {'countryID':108, 'countryCode':'JM', 'countryName':'Jamaica'},
    {'countryID':109, 'countryCode':'JP', 'countryName':'Japan'},
    {'countryID':110, 'countryCode':'JO', 'countryName':'Jordan'},
    {'countryID':111, 'countryCode':'KZ', 'countryName':'Kazakhstan'},
    {'countryID':112, 'countryCode':'KE', 'countryName':'Kenya'},
    {'countryID':113, 'countryCode':'KI', 'countryName':'Kiribati'},
    {'countryID':114, 'countryCode':'KP', 'countryName':'Korea, North'},
    {'countryID':115, 'countryCode':'KR', 'countryName':'Korea, South'},
    {'countryID':116, 'countryCode':'KW', 'countryName':'Kuwait'},
    {'countryID':117, 'countryCode':'KG', 'countryName':'Kyrgyzstan'},
    {'countryID':118, 'countryCode':'LA', 'countryName':'Laos'},
    {'countryID':119, 'countryCode':'LV', 'countryName':'Latvia'},
    {'countryID':120, 'countryCode':'LB', 'countryName':'Lebanon'},
    {'countryID':121, 'countryCode':'LS', 'countryName':'Lesotho'},
    {'countryID':122, 'countryCode':'LR', 'countryName':'Liberia'},
    {'countryID':123, 'countryCode':'LY', 'countryName':'Libya'},
    {'countryID':124, 'countryCode':'LI', 'countryName':'Liechtenstein'},
    {'countryID':125, 'countryCode':'LT', 'countryName':'Lithuania'},
    {'countryID':126, 'countryCode':'LU', 'countryName':'Luxembourg'},
    {'countryID':127, 'countryCode':'MO', 'countryName':'Macau'},
    {'countryID':128, 'countryCode':'MK', 'countryName':'Macedonia'},
    {'countryID':129, 'countryCode':'MG', 'countryName':'Madagascar'},
    {'countryID':130, 'countryCode':'MW', 'countryName':'Malawi'},
    {'countryID':131, 'countryCode':'MY', 'countryName':'Malaysia'},
    {'countryID':132, 'countryCode':'MV', 'countryName':'Maldives'},
    {'countryID':133, 'countryCode':'ML', 'countryName':'Mali'},
    {'countryID':134, 'countryCode':'MT', 'countryName':'Malta'},
    {'countryID':135, 'countryCode':'MH', 'countryName':'Marshall Islands'},
    {'countryID':136, 'countryCode':'MQ', 'countryName':'Martinique'},
    {'countryID':137, 'countryCode':'MR', 'countryName':'Mauritania'},
    {'countryID':138, 'countryCode':'MU', 'countryName':'Mauritius'},
    {'countryID':139, 'countryCode':'YT', 'countryName':'Mayotte'},
    {'countryID':140, 'countryCode':'MX', 'countryName':'Mexico'},
    {'countryID':141, 'countryCode':'FM', 'countryName':'Micronesia'},
    {'countryID':142, 'countryCode':'MD', 'countryName':'Moldova'},
    {'countryID':143, 'countryCode':'MC', 'countryName':'Monaco'},
    {'countryID':144, 'countryCode':'MN', 'countryName':'Mongolia'},
    {'countryID':145, 'countryCode':'MS', 'countryName':'Montserrat'},
    {'countryID':146, 'countryCode':'MA', 'countryName':'Morocco'},
    {'countryID':147, 'countryCode':'MZ', 'countryName':'Mozambique'},
    {'countryID':148, 'countryCode':'MM', 'countryName':'Myanmar'},
    {'countryID':149, 'countryCode':'NA', 'countryName':'Namibia'},
    {'countryID':150, 'countryCode':'NR', 'countryName':'Nauru'},
    {'countryID':151, 'countryCode':'NP', 'countryName':'Nepal'},
    {'countryID':152, 'countryCode':'NL', 'countryName':'Netherlands'},
    {'countryID':153, 'countryCode':'AN', 'countryName':'Netherlands Antilles'},
    {'countryID':154, 'countryCode':'NC', 'countryName':'New Caledonia'},
    {'countryID':155, 'countryCode':'NZ', 'countryName':'New Zealand'},
    {'countryID':156, 'countryCode':'NI', 'countryName':'Nicaragua'},
    {'countryID':157, 'countryCode':'NE', 'countryName':'Niger'},
    {'countryID':158, 'countryCode':'NG', 'countryName':'Nigeria'},
    {'countryID':159, 'countryCode':'NU', 'countryName':'Niue'},
    {'countryID':160, 'countryCode':'NF', 'countryName':'Norfolk Island'},
    {'countryID':161, 'countryCode':'MP', 'countryName':'Northern Mariana Islands'},
    {'countryID':162, 'countryCode':'NO', 'countryName':'Norway'},
    {'countryID':163, 'countryCode':'OM', 'countryName':'Oman'},
    {'countryID':164, 'countryCode':'PK', 'countryName':'Pakistan'},
    {'countryID':165, 'countryCode':'PW', 'countryName':'Palau'},
    {'countryID':166, 'countryCode':'PA', 'countryName':'Panama'},
    {'countryID':167, 'countryCode':'PG', 'countryName':'Papua New Guinea'},
    {'countryID':168, 'countryCode':'PY', 'countryName':'Paraguay'},
    {'countryID':169, 'countryCode':'PE', 'countryName':'Peru'},
    {'countryID':170, 'countryCode':'PH', 'countryName':'Philippines'},
    {'countryID':171, 'countryCode':'PN', 'countryName':'Pitcairn'},
    {'countryID':172, 'countryCode':'PL', 'countryName':'Poland'},
    {'countryID':173, 'countryCode':'PT', 'countryName':'Portugal'},
    {'countryID':174, 'countryCode':'PR', 'countryName':'Puerto Rico'},
    {'countryID':175, 'countryCode':'QA', 'countryName':'Qatar'},
    {'countryID':176, 'countryCode':'RE', 'countryName':'Reunion'},
    {'countryID':177, 'countryCode':'RO', 'countryName':'Romania'},
    {'countryID':178, 'countryCode':'RU', 'countryName':'Russian Federation'},
    {'countryID':179, 'countryCode':'RW', 'countryName':'Rwanda'},
    {'countryID':180, 'countryCode':'SH', 'countryName':'Saint Helena'},
    {'countryID':181, 'countryCode':'KN', 'countryName':'Saint Kitts and Nevis'},
    {'countryID':182, 'countryCode':'LC', 'countryName':'Saint Lucia'},
    {'countryID':183, 'countryCode':'PM', 'countryName':'Saint Pierre and Miquelon'},
    {'countryID':184, 'countryCode':'VC', 'countryName':'Saint Vincent and the Grenadines'},
    {'countryID':185, 'countryCode':'WS', 'countryName':'Samoa'},
    {'countryID':186, 'countryCode':'SM', 'countryName':'San Marino'},
    {'countryID':187, 'countryCode':'ST', 'countryName':'Sao Tome and Principe'},
    {'countryID':188, 'countryCode':'SA', 'countryName':'Saudi Arabia'},
    {'countryID':189, 'countryCode':'SN', 'countryName':'Senegal'},
    {'countryID':190, 'countryCode':'SC', 'countryName':'Seychelles'},
    {'countryID':191, 'countryCode':'SL', 'countryName':'Sierra Leone'},
    {'countryID':192, 'countryCode':'SG', 'countryName':'Singapore'},
    {'countryID':193, 'countryCode':'SK', 'countryName':'Slovakia'},
    {'countryID':194, 'countryCode':'SI', 'countryName':'Slovenia'},
    {'countryID':195, 'countryCode':'SB', 'countryName':'Solomon Islands'},
    {'countryID':196, 'countryCode':'SO', 'countryName':'Somalia'},
    {'countryID':197, 'countryCode':'ZA', 'countryName':'South Africa'},
    {'countryID':198, 'countryCode':'GS', 'countryName':'South Georgia'},
    {'countryID':199, 'countryCode':'ES', 'countryName':'Spain'},
    {'countryID':200, 'countryCode':'LK', 'countryName':'Sri Lanka'},
    {'countryID':201, 'countryCode':'SD', 'countryName':'Sudan'},
    {'countryID':202, 'countryCode':'SR', 'countryName':'Suriname'},
    {'countryID':203, 'countryCode':'SJ', 'countryName':'Svalbard and Jan Mayen'},
    {'countryID':204, 'countryCode':'SZ', 'countryName':'Swaziland'},
    {'countryID':205, 'countryCode':'SE', 'countryName':'Sweden'},
    {'countryID':206, 'countryCode':'CH', 'countryName':'Switzerland'},
    {'countryID':207, 'countryCode':'SY', 'countryName':'Syria'},
    {'countryID':208, 'countryCode':'TW', 'countryName':'Taiwan'},
    {'countryID':209, 'countryCode':'TJ', 'countryName':'Tajikistan'},
    {'countryID':210, 'countryCode':'TZ', 'countryName':'Tanzania'},
    {'countryID':211, 'countryCode':'TH', 'countryName':'Thailand'},
    {'countryID':212, 'countryCode':'TG', 'countryName':'Togo'},
    {'countryID':213, 'countryCode':'TK', 'countryName':'Tokelau'},
    {'countryID':214, 'countryCode':'TO', 'countryName':'Tonga'},
    {'countryID':215, 'countryCode':'TT', 'countryName':'Trinidad and Tobago'},
    {'countryID':216, 'countryCode':'TN', 'countryName':'Tunisia'},
    {'countryID':217, 'countryCode':'TR', 'countryName':'Turkey'},
    {'countryID':218, 'countryCode':'TM', 'countryName':'Turkmenistan'},
    {'countryID':219, 'countryCode':'TC', 'countryName':'Turks and Caicos Islands'},
    {'countryID':220, 'countryCode':'TV', 'countryName':'Tuvalu'},
    {'countryID':221, 'countryCode':'UG', 'countryName':'Uganda'},
    {'countryID':222, 'countryCode':'UA', 'countryName':'Ukraine'},
    {'countryID':223, 'countryCode':'AE', 'countryName':'United Arab Emirates'},
    {'countryID':224, 'countryCode':'GB', 'countryName':'United Kingdom'},
    {'countryID':225, 'countryCode':'UK', 'countryName':'United Kingdom - UK'},
    {'countryID':226, 'countryCode':'UM', 'countryName':'United States Minor Outlying Islands'},
    {'countryID':227, 'countryCode':'UY', 'countryName':'Uruguay'},
    {'countryID':228, 'countryCode':'UZ', 'countryName':'Uzbekistan'},
    {'countryID':229, 'countryCode':'VU', 'countryName':'Vanuatu'},
    {'countryID':230, 'countryCode':'VA', 'countryName':'Vatican City'},
    {'countryID':231, 'countryCode':'VE', 'countryName':'Venezuela'},
    {'countryID':232, 'countryCode':'VN', 'countryName':'Viet Nam'},
    {'countryID':233, 'countryCode':'VG', 'countryName':'Virgin Islands, British'},
    {'countryID':234, 'countryCode':'VI', 'countryName':'Virgin Islands, U.S.'},
    {'countryID':235, 'countryCode':'WF', 'countryName':'Wallis and Fortuna Islands'},
    {'countryID':236, 'countryCode':'EH', 'countryName':'Western Sahara'},
    {'countryID':237, 'countryCode':'YE', 'countryName':'Yemen'},
    {'countryID':238, 'countryCode':'YU', 'countryName':'Yugoslavia'},
    {'countryID':239, 'countryCode':'ZR', 'countryName':'Zaire'},
    {'countryID':240, 'countryCode':'ZM', 'countryName':'Zambia'},
    {'countryID':241, 'countryCode':'ZW', 'countryName':'Zimbabwe'}
  ];

  listDocumentType = [
    { documentTypeID: 0, documentTypeName: 'DNI'},
    { documentTypeID: 1, documentTypeName: 'Carnet de extranjería'},
    { documentTypeID: 2, documentTypeName: 'Pasaporte'}
  ];



  constructor(private fb: FormBuilder,
              private accountService: AccountService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private toastr: ToastrService,
              private busyService: BusyService,
              private basketService: BasketService,
              private checkoutService: CheckoutService,
              public sanitizer: DomSanitizer) { }

  ngOnInit() {
    window.scroll(0,0);
    this.loadBasket();
    this.createCardForm();
    this.promoCode = "";
    this.payusecurity = "";
    this.payusecuritycode = "";
    this.getPayUSecurity();
  }

  createCardForm() {
    this.messages = [];
    this.cardForm = this.fb.group({
      cardNumber: [
        null,
        [
          Validators.required,
          Validators.minLength(11)
        ]
      ],
      month: [
        this.listMonthNumber[0],
        Validators.compose([
          Validators.required
        ])
      ],
      year: [
        this.listYear[0],
        Validators.compose([
          Validators.required
        ])
      ],
      CCV: [
        null,
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(4),
          Validators.pattern('^[0-9]{3,4}$')
        ]
      ],
      name: [
        null,
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(255),
        ]
      ],
      login: [
        null,
        [
          Validators.required,
          Validators.pattern('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$')
        ]
      ],
      documentNumber: [ null, [Validators.required ] ],
      address1: [ null, [Validators.required ] ],
      address2: [ null ],
      country: [
        this.listAllCountries[168],
        Validators.compose([
          Validators.required
        ])
      ],
      city: [
        null,
        [
          Validators.required
        ]
      ],
      state: [
        null,
        [
          Validators.required
        ]
      ],
      postalCode: [
        null,
        [
          Validators.required
        ]
      ],
      phoneNumber: [
        null,
        [
          Validators.required,
          Validators.pattern('^\\+?[\\0-9]{5,20}$')
        ]
      ]
    });
    // auto selectionation
  }

  getPayUSecurity()
  {
    console.log('entro getPayUSecurity');    
    
    /*
      this.basketService.getPayUSecurity().subscribe(response => {
        
        this.payusecurity = response.message;
        document.getElementById("payuIFrame").setAttribute("src", 'https://maf.pagosonline.net/ws/fp/tags.js?id=' + response.message);

        this.payusecurity = 'https://maf.pagosonline.net/ws/fp/tags.js?id=' + md5(session + time) + '80200';
        this.payusecuritySafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.payusecurity);
        this.insertPayUScript(this.payusecurity);
      });
      */

     console.log('generando');
     const session = this.uuidv4();
     const time = this.microtime(true);
     this.payusecuritycode = md5(session + time);
     this.payusecurity = 'https://maf.pagosonline.net/ws/fp/tags.js?id=' + this.payusecuritycode + '80200';
     this.payusecuritySafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.payusecurity);
     this.insertPayUScript(this.payusecurity);
     
  }

  insertPayUScript(src : any)
  {
    const head = document.getElementsByTagName('head')[0];
    const _js = document.createElement('script');
    _js.type = 'text/javascript';
    _js.src = src;
    head.appendChild(_js);
  }

  uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  loadBasket() {
    if (localStorage.getItem('basket_id') === null) {
      this.router.navigateByUrl('/');
      return;
    }

    let bid = parseInt(localStorage.getItem('basket_id'), 10);

    if (isNaN(bid) || bid === null) {
      bid = 0;
    } else {
      bid = parseInt(localStorage.getItem('basket_id'), 10);
    }

    let token = parseInt(localStorage.getItem('user_id'), 10);

    if (isNaN(token) || token === null) {
      token = 0;
    } else {
      token = parseInt(localStorage.getItem('user_id'), 10);
    }

    this.requestCart = {
      "basketID": bid,
      "userID": token
    };

    this.basketService.getFull(this.requestCart).subscribe(response => {
      if ( response.status === false ) {
        this.router.navigateByUrl('/');
      } else {
        this.promoCode = response.data.promoCode;
        this.basketItems = response.data.items;
        try {
          GAPPS.SHIPPINGMETHOD(this.basketItems);
        } catch (e) {
          console.log(e);
          console.log('^ catch error');
        }
        this.basketTotals = response.data;
        this.totals = response.data.total;
      }
    }, error => {
      console.log(error);
    });
  }

  microtime(getAsFloat) {
    var s,
        now = (Date.now ? Date.now() : new Date().getTime()) / 1000;

    // Getting microtime as a float is easy
    if(getAsFloat) {
        return now
    }

    // Dirty trick to only get the integer part
    s = now | 0

    return (Math.round((now - s) * 1000) / 1000) + ' ' + s
}

  onSubmit() {
    this.messages = [];


    const isPhonePattern = new RegExp('^\\+?[\\0-9]{5,20}$');
    const isCCVPattern = new RegExp('^[0-9]{3,4}$');
    const isEmailPattern = new RegExp('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$');

    if (this.cardForm.value.cardNumber === ''   ||
        this.cardForm.value.cardNumber === null ||
        typeof this.cardForm.value.cardNumber === 'undefined') {
      this.messages.push('Inserte el nro de tarjeta');
      return;
    }

    if (this.cardForm.value.name === ''   ||
        this.cardForm.value.name === null ||
        typeof this.cardForm.value.name === 'undefined') {
      this.messages.push('Inserte su nombre');
      return;
    }

    if (this.cardForm.value.month.monthNumberName === 'Mes'||
        this.cardForm.value.month.monthNumberName === null ||
        typeof this.cardForm.value.month.monthNumberName === 'undefined') {
      this.messages.push('Seleccione un mes');
      return;
    }

    if (this.cardForm.value.year.year === 'Año'||
        this.cardForm.value.year.year === null ||
        typeof this.cardForm.value.year.year === 'undefined') {
      this.messages.push('Seleccione un año');
      return;
    }

    if (this.cardForm.value.CCV === ''   ||
        this.cardForm.value.CCV === null ||
        typeof this.cardForm.value.CCV === 'undefined' ||
        !isCCVPattern.test(this.cardForm.value.CCV)) {
      this.messages.push('Inserte el CCV');
      return;
    }

    if (this.cardForm.value.login === ''   ||
        this.cardForm.value.login === null ||
        typeof this.cardForm.value.login === 'undefined' ||
        !isEmailPattern.test(this.cardForm.value.login)) {
      this.messages.push('Inserte su correo');
      return;
    }

    if (this.cardForm.value.documentNumber === ''   ||
        this.cardForm.value.documentNumber === null ||
        typeof this.cardForm.value.documentNumber === 'undefined') {
      this.messages.push('Inserte su Nro de documento');
      return;
    }

    if (this.cardForm.value.address1 === ''   ||
        this.cardForm.value.address1 === null ||
        typeof this.cardForm.value.address1 === 'undefined') {
      this.messages.push('Inserte dirección 1');
      return;
    }

    if (this.cardForm.value.address2 === ''   ||
        this.cardForm.value.address2 === null ||
        typeof this.cardForm.value.address2 === 'undefined') {
      // is optional
    }

    // dropdown country is ever selected

    if (this.cardForm.value.city === ''   ||
        this.cardForm.value.city === null ||
        typeof this.cardForm.value.city === 'undefined') {
      this.messages.push('Inserte ciudad');
      return;
    }

    if (this.cardForm.value.state === ''   ||
        this.cardForm.value.state === null ||
        typeof this.cardForm.value.state === 'undefined') {
      this.messages.push('Inserte departamento');
      return;
    }

    if (this.cardForm.value.postalCode === ''   ||
        this.cardForm.value.postalCode === null ||
        typeof this.cardForm.value.postalCode === 'undefined') {
      this.messages.push('Inserte el código postal');
      return;
    }

    if (this.cardForm.value.phoneNumber === ''   ||
        this.cardForm.value.phoneNumber === null ||
        typeof this.cardForm.value.phoneNumber === 'undefined' ||
        !isPhonePattern.test(this.cardForm.value.phoneNumber)) {
      this.messages.push('Inserte el nro telefónico');
      return;
    }

    const payObj = {
      'UserID': parseInt(localStorage.getItem('user_id'), 10),
      'BasketID': parseInt(localStorage.getItem('basket_id'), 10),
      'card': {
        'cc_number': this.cardForm.value.cardNumber,
        'cc_sec_code': this.cardForm.value.CCV,
        'cc_year': parseInt(this.cardForm.value.year.year, 10),
        'cc_month': parseInt(this.cardForm.value.month.monthNumberName, 10),
        'cc_name': this.cardForm.value.name
      },
      'billingAddress' : {
        'Email': this.cardForm.value.login,
        'Name': this.cardForm.value.name,
        'Company': '',
        'Line1': this.cardForm.value.address1,
        'Line2': this.cardForm.value.address2,
        'City': this.cardForm.value.city,
        'State': this.cardForm.value.state,
        'Zip': this.cardForm.value.postalCode,
        'Country': this.cardForm.value.country.countryCode,
        'Phone': this.cardForm.value.phoneNumber,
        'StateID': '0',
        'DocumentNumber': this.cardForm.value.documentNumber,
        'AddressName': this.cardForm.value.address1
      },
      'payUSecurity': this.payusecuritycode
    };

    this.checkoutService.basketPlaceOrderCC(payObj).subscribe(response => {
      if(response.status === true) {
        localStorage.setItem('basket_id', '0');
        this.router.navigateByUrl('/gracias/'+response.data);
      } else {
        this.toastr.warning(response.message,'',{
          tapToDismiss: true
        });
      }
    }, error => {
      this.errors = true;
      this.message = error.errors;
      console.log(error);
    });
  }

  placeorderWT(prods, paymentmethod) {

    const payObj = {
      "basketID": this.accountService.getSimpleBasketID(),
      "userID": this.accountService.getSimpleUserID()
    };

    this.checkoutService.basketPlaceOrderWT(payObj).subscribe(response => {
      if(response.status === true) {
        try {
          GAPPS.PAYMENTMETHOD(prods, paymentmethod);
          count_cart_items_quantity('init',0);
        } catch(e) {
          console.log(e);
          console.log('^ catch error');
        }
        localStorage.setItem('basket_id', '0');
        this.router.navigateByUrl('/gracias/'+response.data);
      } else {
        this.toastr.warning(response.message,'',{
          tapToDismiss: true
        });
      }
    }, error => {
      this.errors = true;
      this.message = error.errors;
      console.log(error);
    });

  }

}
